<template>
  <div>
    <CompaniesList listStatus="all"  />
  </div>
</template>

<script>

import CompaniesList from "@/components/Company/CompaniesList";
export default {
  name: "Company",
  components: {
    CompaniesList
  },
  data(){
    return {
      updateCompany: false
    }
  },
  methods:{

  },
  created(){
  }
}
</script>

<style scoped>

</style>
